.button {
    margin: 0;
    padding: 0;
}

.popup {
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.popupText {
    visibility: hidden;
    width: 60px;
    /*background-color: #555;*/
    background-color: white;
    border: solid 1px #555;
    /*color: #fff;*/
    text-align: center;
    border-radius: 6px;
    padding: 2px 4px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    right: -20px;
    /*left: 10%;*/
    /*margin: 0 -2em;*/
}

.popupText::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.show {
    visibility: visible;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity:1 ;}
}
