.header {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
}

.label {
    width: 50px;
}

.input {
    flex: 1;
}

.buttons {
    margin-right: 20px;
    min-width: fit-content;
    flex-direction: column;
    display: flex;
}

.buttons.row {
    flex-direction: row;
    display: flex;
}

.button {
    height: fit-content;
    width: 62px;
}
.button.send {
    height: 100%;
}

.wrapper {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 5px;
    min-height: 300px;
    margin-right: 20px;
}

.wrapper > .toolbar {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
}

.editor {
}
