.wrapper {
  display: flex;

  .fields {
    flex: 1;

    .notes {
      min-height: 15rem;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
  }
}
