.container {
  display: flex;
}

.left {
  width: 40%;
}

.right {
  flex: 1;
}
