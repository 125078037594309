.root {
  position: relative;
  display: inline-block;
}

.icon {
  width: 24px;
  height: 24px;
  padding: 4px;
  border-radius: 50%;
  background: #000;
  fill: #fff;
}

.info {
  user-select: none;
  cursor: pointer;
}

.arrow {
  margin-left: 10px;
}
