.results {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 20px;
    top: 43px;
    text-align: left;
    background: #fff;
    padding: 10px 20px;
    box-shadow: 0 10px 40px 0 rgba(0,0,0,.15);
    border: 1px solid #ccc;
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.group {
    margin: 20px 0;
}

.group h3 {
    text-align: left;
    font-size: 16px;
    margin: 0;
}

.group h3 .count {
    color: #ccc;
    font-family: initial;
}

.group a {
    display: block;
    text-overflow: ellipsis;
    padding: 5px 20px;
    margin: 0 -20px;
}
.group a:hover {
    background: #eee;
}

.group h3 + a {
    margin-top: 5px;
}
