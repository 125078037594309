.root {
    background: #fff;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    min-width: 160px;
    padding: 10px 0;
    margin: 10px 0 0 0;
    list-style: none;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
}

.alignRight {
    right: 0;
    left: auto;
}

.item {
    margin: 0 0 5px 0;
    text-align: left;
}

.item:last-child {
    margin-bottom: 0;
}

.item > a {
    transition: all 0.15 ease-in-out;
    text-decoration: none;
    font-weight: normal;
    display: block;
    padding: 6px 30px;
    clear: both !important;
    line-height: 1.428571429;
    white-space: nowrap;
    color: #333;
}

.item > div.component {
    transition: all 0.15 ease-in-out;
    text-decoration: none;
    font-weight: normal;
    display: block;
    padding: 6px 30px;
    clear: both !important;
    line-height: 1.428571429;
    white-space: nowrap;
    color: #333;
}

.item > a:hover {
    background: #ededed;
    color: #000;
}

.item > div.component:hover {
    background: #ededed;
    /*color: #000;*/
}

.item > a.active {
    color: #000;
}
