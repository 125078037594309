.title {
    margin-bottom: 0;
    text-align: left;
}

.itemContainer {
    padding-bottom: 0;
}

.label {
    display: inline-block;
    width: 200px;
}

.value {
    display: inline-block;
}
