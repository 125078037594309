.rssIcon {
  font-size: 16px;
  color: gray;
}
.rssIcon-active-audio {
  color: green;
}
.rssIcon-active-video {
  color: orange;
}


form .toggleEdit {
  margin-top: 4px;
}

.editContainer {
  display: flex;
  flex-direction: row;
}

.editContainer > div:first-child {
  flex: 1;
}

.seriesListButton {
  text-transform: none;
  text-decoration: none;
  padding: 0;
  margin: 0;
}


.saveWrapper {
  text-align: right;
  padding-left: 30px;
  display: flex;
}

.seriesData {
  margin-bottom: 0.5em
}

.formWrapper {
    display: flex;
    flex-direction: row;
}

.formWrapper > *:last-child {
    flex: 1;
}

.image {
  display: inline-block;
  max-width: 200px;
  max-height: 200px;
  margin-right: 0.5em;
  object-fit: contain;
}

.fieldsBlock {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 140px - 0.5em);
}

.fieldsRow + .fieldsRow {
  margin-top: 0.5em;
}

.fieldName {
  display: inline-block;
  vertical-align: top;
  width: calc(100px);
}
.fieldValue {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 100px - 0.5em);
  margin-left: 0.5em;
  white-space: pre-wrap;
}

label.label {
  width: 100px;
}

.analyticsContainer {
    display: flex;
    flex-direction: column;
    text-align: right;
    padding-left: 30px;
}
