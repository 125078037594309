.header {
  position: relative;
}

.filter {
  margin-top: -24px;
}

.title .count {
    color: #ccc;
    font-size: 18px;
    font-weight: normal;
    margin-left: 10px;
    /*font-family: 'Lato', Helvetica, Arial, sans-serif;*/
    position: relative;
}

.actionContainer {
    position: absolute;
    display: flex;
    right: 0;
    height: 100%;
}
.actionContainer > * {
    margin-top: auto;
    margin-bottom: auto;
}

.item {
    border-bottom: 1px solid #ddd;
    padding: 15px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #333;
    /*font-family: "Lato", sans-serif;*/
}

.item:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.name {
    font-size: 16px;
    font-weight: bold;
}

.itemInfo {
    width: 100%;
}

.itemData {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.propertyContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.icons {
    display: flex;
}

.baseInfo {
    display: inline-block;
    flex: 1;
    font-size: 14px;
}

.preview {
    width: 150px;
    height: 75px;
    margin: 0 20px 0 0;
}
.previewImage {
    width: 150px;
    height: 75px;
    object-fit: contain;
}
.previewPlaceholder {
    fill: rgb(51, 51, 51);
    outline: rgb(51, 51, 51) solid 1px;
    outline-offset: -1px;
}

.middle {
    padding: 0 10px;
    font-size: 16px;
    white-space: nowrap;
    width: 10%;
    text-align: center;
}

.id {
    display: inline-block;
    font-size: 16px;
    width: 30%;
    text-align: center;
}

label.label {
    width: 100px;
}
