.container {
    position: relative;
}

.track {
    text-align: center;
    top: 80px;
}
.organize {
    top: 130px;
    left: 15px;
}
.publish {
    text-align: right;
    top: 130px;
    right: 25px;
}
.own {
    text-align: center;
    top: 180px;
    color: white;
}
.local {
    top: 250px;
    left: 105px;
}
.cloud {
    text-align: right;
    top: 250px;
    right: 105px;
}
.backup {
    text-align: center;
    top: 360px;
}
