.row {
  //border: 1px solid gray;
  display: grid;
  grid-template-columns: minmax(75px, 1fr) minmax(225px, 3fr);
  cursor: pointer;
  grid-gap: 4px;
}

.row > div {
  padding-top: 4px;
  padding-bottom: 4px;
}

.row:nth-child(2n+1) {
  background-color: var(--bg2ndRow);
}

.row:hover {
  background-color: var(--bgHover);
}

