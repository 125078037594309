.row {
  //border: 1px solid gray;
  display: grid;
  grid-template-columns: 7em minmax(150px, 1fr) minmax(50px, 2fr);
  cursor: pointer;
}

.row > div:first-child {
  padding: 4px;
}

.row:nth-child(2n+1) {
  background-color: var(--bg2ndRow);
}

.row:hover {
  background-color: var(--bgHover);
}

.notActive {
  color: var(--lightText);
}

.fields {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

.fields > div {
  padding: 4px;
}

.roles {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
}

.roles > div {
  padding: 4px;
  place-self: center;
}

.search {
  text-align: right;
}

.spinner {
  text-align: center;
}

.filterPanel {
  display: flex;
}

.flex1 {
  flex: 1;
}
