.title {
  //margin: 0 5% 40px 5%;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 40px;
  width: 90%;
  line-height: 1;
}

.noLabelContainer {
  & > div {
    padding-bottom: 2ex;
  }
}

.flags {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flagLabel {
  margin-right: 0.8ex;
  width: unset;

  span {
    svg {
      margin-left: 0.8ex;
    }
  }
}

.addFilesButton {
  font-size: inherit;
}

.lightText {
  color: var(--lightText);
}

.flexCenter {
  display: flex;
  align-items: center;
}
