.wrapper {
    text-align: center;
}

.wrapper p {
    font-size: 1.1em;
    margin: 2em;
}

.wrapper a {
    text-decoration: underline;
}
