.inputRoot {
  display: inline-block;
  padding: 0;
  height: 24px;
  margin-left: 8px;
}

.inputRoot:after {
  display: none;
}

.inputWrapper {
  min-height: unset;
  padding-right: 8px;
}

.icon {
  display: flex;
  width: 24px;
}
