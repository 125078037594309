.container {
    min-height: 100%;
}

.rwMultiSelectTagListReadOnly {
    min-height: 100%;
    margin: 5px 0 0 0;
    padding: 0;
    list-style: none;
    display: inline-block;
    vertical-align: 0;
    outline: none;
}

.rwMultiSelectTagListReadOnly > li:first-child {
    margin-left: 0;
}

.button {
  padding: 0;
}

.button * {
  cursor: pointer;
}
