.menuContainer {
    display: flex;
    flex-direction: row;
    padding-top: 14px;
    margin-bottom: 10px;
}

.menuContainer > a:first-child {
    margin-left: 0;
}

.menuContainer > a:last-child {
    margin-right: 0;
}

.menuItem {
    margin: 0 10px;
    color: #9e9e9e;
    white-space: nowrap;
}

.menuItemActive {
    text-decoration: underline;
    color: black;
}
