.text {
    text-align: center;
}

.imageArrow {
    position: absolute;
    top: 30px;
    right: -10px;
}

