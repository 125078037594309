.localSettingsContainer {
    /*width: 100%;*/
    width: 650px;
    padding-left: 3px;
    padding-right: 3px;
    position: relative;
    margin: 0 auto;
}

.editContainer {
    display: flex;
    flex-direction: row;
}

.editContainer > div:first-child {
    flex: 1;
}

.label {
    width: 120px;
}

.form {
    margin: 30px 30px 30px 30px;
}

.folderButton {
    margin-top: 9px;
}

.helpContainer {
    /*width: 580px;*/
    margin: 0 auto;
    text-align: center;
}

.helpText {
    font-family: 'Henriette Black', Georgia, serif;
    /*text-align: center;*/
    font-size: 24px;
    margin-bottom: 10px;
}
