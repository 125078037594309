.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.content {
    padding-top: 30px;
    width: 100%;
    max-width: 600px;
}
