.itemImage {
  margin-top: -3px;
  height: 17px;
}

.imgContainer {
  display: inline-flex;
  place-content: center;
  width: 30px;
  margin-right: 8px;
}

.logoWrapper {
  width: 100%;
  height: 100px;
  /*width: 158px;*/
  /*min-width: 158px;*/
  /*height: fit-content;*/
  /*height: 100%;*/
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom {
  margin-top: auto;
}


.item {
  display: flex;
  align-items: center;
  padding: 0 8px;
  //line-height: 20px;
  color: white;
  //font-size: 14px;
  cursor: pointer;

  & > .title {
    //margin-left: 0.5rem;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:not(.active):hover {
    color: white;
    background-color: #3f3f3f;
    font-weight: bold;
    filter: invert(100%);
  }

  &.active {
    border-right: none;
    border-left: none;
    position: relative;
  }
}


.navItem {
  height: 47px;
  text-transform: uppercase;

  &.active {
    background: white;
    border: 1px solid #e7e7e8;
    color: black;
  }

  &.active:after {
    content: '';
    z-index: 2;
    position: absolute;
    right: -23px;
    top: 0;
    width: 0;
    height: 0;
    border-top: 23px solid transparent;
    border-bottom: 22px solid transparent;
    border-left: 23px solid white;
  }

  &.active span {
    content: '';
    z-index: 1;
    position: absolute;
    right: -25px;
    top: -2px;
    width: 0;
    height: 0;
    border-top: 25px solid transparent;
    border-bottom: 24px solid transparent;
    border-left: 25px solid #e7e7e8;
  }

  &.active svg {
    filter: invert(100%);
  }
}

.recentItem {
  height: 28px;
  margin-top: 16px;

  & > .imgContainer {
    width: 20px;
  }

  &.active {
    background: #3f3f3f;
  }

  &.active:hover {
    color: white;
  }

  &.active span {
    display: none;
  }
}

