@value bigButtonPadding: 4px 25px;
@value clrPrimary: #000000ff;
@value clrPrimaryHover: #0000007f;
@value clrGrayBtn: #d1d3d4ff;
@value clrGrayBtnHover: #d1d3d47f;
@value clrWarningBtn: #bf1e2dff;
@value clrWarningBtnHover: #bf1e2d7f;

.button {
    font-size: 14px;
    line-height: 34px;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    border-radius: 0.8em;
    background-clip: padding-box;
    padding: 2px 16px;
    margin-left: 5px;
    display: inline-block;
    transition: all 0.25 ease-in-out;
    user-select: none;
    border: none;
    /*border: 2px solid black;*/
    font-family: "Lato", -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif;
    word-spacing: normal;
    text-align:center;
}

.button.small {
    padding: 2px 12px;
    line-height: 24px;
    font-size: 12px;
    border-radius: 0.7em;
}

.button.tiny {
    padding: 0 6px;
    margin: 0;
    line-height: 20px;
    font-size: 12px;
    border-radius: 0.7em;
}

.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.button .icon {
    margin: 0 7px 0 0;
}

.button.iconOnly .icon {
    margin: 0;
}


.bordered {
    background: transparent;
    border-width: 1px;
    border-style: solid;
}

.button-primary {
    background: #111;
    border-color: #111;
    color: #eee;
}

.button-primary:hover {
    background: clrPrimaryHover;
    border-color: clrPrimaryHover;
    color: #fff;
}

.button-primary.bordered {
    background: transparent;
    border-color: #777;
    color: #000;
}

.button-primary.bordered:hover {
    background: transparent;
    text-decoration: underline;
    border-color: #000;
}

.button-gray {
    background: clrGrayBtn;
    border-color: clrGrayBtn;
}
.button-gray.bordered {
    background: transparent;
    box-shadow: inset 0 0 0 1px #d8d8d8;
    border: none;
}

.button-gray:hover {
    color: #fff;
    background: clrGrayBtnHover;
    border-color: clrGrayBtnHover;
}

.button-warning {
    background: clrWarningBtn;
    border-color: clrWarningBtn;
}
.button-warning:hover {
    color: #fff;
    background: clrWarningBtnHover;
    border-color: clrWarningBtnHover;
}

.button-none {
    border-color: transparent;
    background: transparent;
    color: #000;
    text-decoration: underline;
}

.button-white {
    background: white;
    border-color: white;
    color: black;
}

.button-white:hover {
    background: #dddddd;
    border-color: #dddddd;
    color: black;
}

.noTextDecoration {
    text-decoration: none;
    text-transform: none;
}
