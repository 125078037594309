form .toggleEdit {
  margin-top: 4px;
}

.editContainer {
  display: flex;
  flex-direction: row;
}

.editContainer > div:first-child {
  flex: 1;
}

.saveWrapper {
  text-align: right;
  padding-left: 30px;
}

.label {
  width: 140px;
}

.value {
  padding: 10px 0;
  line-height: 20px;
}

.status {
  display: inline-block;
  vertical-align: middle;
  line-height: 40px;
  padding: 6px 0;
}

.flex1 {
  flex: 1;
}

label.alignRight {
  text-align: right;
}

.fitContent {
  width: fit-content;
}

.flex {
  display: flex;
}
