.header {
  margin-bottom: 0;
}

.description {
  margin-top: 1rem;
  text-align: center;
}

.actions {
  display: flex;
  margin-top: 1rem;

  & > .action:first-child {
    margin-left: 0;
  }
  & > .action:last-child {
    margin-right: 0;
  }
}

.action {
  margin: 0 auto;
}

.footer {
  margin-top: 1rem;
}
