.container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.title {
    margin-bottom: 20px;
}

.page{
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
}
