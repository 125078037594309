.container {
    justify-content: center;
    width: 100%;
}

.text {
    text-align: left;
    margin-top: 0;
    margin-bottom: 0;
}

.imageExample {
    margin: -5px auto -5px auto;
    /*margin: -40px auto -30px auto;*/
    text-align: center;
    display: block;
    height: 250px;
}

.imageHand2 {
    height: 58px;
    padding-bottom: 10px;
}
