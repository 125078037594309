.button {
  background: var(--btnPrimaryBg);
  border-color: var(--btnPrimaryBg);
  color: var(--btnPrimaryText);
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  background-clip: padding-box;
  margin-left: 5px;
  display: inline-block;
  transition: all 0.25 ease-in-out;
  user-select: none;
  border: none;
  /*border: 2px solid black;*/
  font-family: "Lato", -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif;
  word-spacing: normal;
  text-align:center;

  padding: 2px 12px;
  line-height: 24px;
  font-size: 12px;
  border-radius: 0.7em;
  min-width: 1.4em;
}

.noTextTransform {
  text-decoration: none;
  text-transform: none;
}

.button:hover {
  opacity: 0.5;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.big {
  font-size: 14px;
  line-height: 34px;
  border-radius: 0.8em;
  padding: 2px 16px;
}
.tiny {
  padding: 0 6px;
  margin: 0;
  line-height: 20px;
  border-radius: 0.4em;
}

.secondary {
  background: var(--btnSecondaryBg);
  border-color: var(--btnSecondaryBg);
  color: var(--btnSecondaryText);
}
.warning {
  background: var(--btnWarningBg);
  border-color: var(--btnWarningBg);
  color: var(--btnWarningText);
}
.none {
  background: none;
  border-color: unset;
  color: var(--btnNoneText);
}
.none:hover {
  background: var(--btnNoneHover);
}
.transparent {
  background: var(--btnTransparentBg);
  border-color: var(--btnTransparentBg);
  color: var(--btnTransparentText);
}

.iconBefore > span {
  margin-left: 7px;
}
.iconAfter > span {
  margin-right: 7px;
}
.onlyIcon {
  padding-left: 0;
  padding-right: 0;
}
