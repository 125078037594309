.container {
    display: flex;
    flex-direction: column;
    width: fit-content;
    position: relative;
}

.container * {
    z-index: 3;
}

.container h2.number {
    text-align: center;
    margin: 0;
}

.notLast:after {
    content: '';
    z-index: 2;
    position: absolute;
    right: -23px;
    top: 0px;
    width: 0;
    height: 0;
    border-top: 78px solid transparent;
    border-bottom: 78px solid transparent;
    border-left: 52px solid white;
}

.notLast > span {
    content: '';
    z-index: 1;
    position: absolute;
    right: -25px;
    top: -2px;
    width: 0;
    height: 0;
    border-top: 80px solid transparent;
    border-bottom: 80px solid transparent;
    border-left: 54px solid #b3b3b3;
}

.text {
    flex: 1;
}

.image {
}
