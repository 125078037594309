.text {
    font-family: 'Henriette Black', Georgia, serif;
    margin: 0;
    position: absolute;
    width: 100%;
}

.level-0 {
    font-family: "Lato", -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif;
}

.level-1 {
    font-size: 40px;
}

.level-2 {
    font-size: 28px;
}

.level-3 {
    font-size: 22px;
}
