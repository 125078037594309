.wrapper {
    height: 100%;
    margin: 0 20px 0 0;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    vertical-align: center;
}

/*.logoWrapper {*/
/*    width: 158px;*/
/*    min-width: 158px;*/
/*    height: 100%;*/
/*    background-color: black;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*}*/

.user {
    /*width: 150px;*/
    width: fit-content;
    vertical-align: middle;
    text-align: right;
    padding: 15px 0 15px 20px;
    border-bottom: 1px solid #ddd;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.user .logout {
    color: lightgrey;
}

.user > * {
  margin-right: 8px;
}
.user > *:last-child {
  margin-right: 0;
}

.searchBar {
    border-bottom: 1px solid #ddd;
    margin-left: 23px;
    padding: 15px 20px;
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
}

ul.menuContainer {
    right: 0;
    left: auto;
}

.userButton {
  font-size: 14px;
  padding: 0;
}
