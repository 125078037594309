.bar {
    display: flex;
    min-height: 240px;
}

.bar > div {
    margin-right: 40px;
}

.bar > div:first-child {
    margin-left: auto;
}

.bar > div:last-child {
margin-right: auto;
}
