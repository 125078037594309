@value switchHeight: 24px;
@value scaleFactor: 1.625;
@value ballSpacing: 2px;

/* The switch - the box around the slider */
.container {
    cursor: pointer;
    position: relative;
    display: inline-block;
    height: 100%;
}

.switchContainer {
    width: calc(switchHeight*scaleFactor);
    height: switchHeight;
    /*margin-bottom: 7px;*/
}

.checkContainer {
    width: switchHeight;
    height: switchHeight;
    margin: 0;
}

/* Hide default HTML checkbox */
.container input {
  opacity: 0;
  width: 0;
  height: 0;
  display: flex;
}


.icon.checkIcon {
    width: calc(17px/0.875);
    height: calc(17px/0.875);
    position: absolute;
    top: 1px;
}

.checkbox {
    /*display: inline-flex;*/
    position: relative;
    width: 24px;
    /*height: 2px;*/
    /*align-items: center;*/
    /*justify-content: center;*/
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.readOnly {
    /*nothing*/
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: black;
}

input:focus + .slider {
  box-shadow: 0 0 1px black;
}

input + .readOnly {
    background-color: #E8E9EA;
    cursor: not-allowed;
}
input:checked + .readOnly {
    background-color: #E8E9EA;
    cursor: not-allowed;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}
