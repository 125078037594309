.container {
    padding: 10px 30px;
    position: relative;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
}

.fullWidth {
    width: 100%;
    max-width: 100%;
}

.noPadding {
    padding: 0;
}
