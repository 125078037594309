.error {
  color: red;
  font-weight: bold;
  text-align:center;
}

label.label {
  width: 100px;
}

.container {
    min-width: 600px;
}
