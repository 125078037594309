.off {
    opacity: 0.18;
    filter: grayscale(1);
}

.container {
  position: relative;
  display: inline-flex;
  margin: 0 10px 0 0;
}

.noMargin {
    margin: 0;
}

.img {
  height: 20px;
}
