.buttons {
  margin-top: 16px;
  text-align: center;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flex1 {
  flex: 1;
  align-self: center;
}

.copyButton {
  margin-right: 20px;
}

.template {
  height: 300px;
}
