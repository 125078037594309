.container {
    margin: 0 auto;
}

.image {
    height: 180px;
}

div.container h2 {
    text-align: center;
    margin-left: 0;
}

.container a {
    text-transform: none;
    text-decoration: none;
}

.container a:hover {
    text-decoration: underline;
}

