.background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 3;
  display: block;
}

.noBackground {
  display: none;
}

.windowContainer {
  position: fixed;
  background-color: #00000000;
  top: 0;
  left: 158px; /* nav width */
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  z-index: 4;
}

.container {
  min-width: 80%; //400px;
  max-width: 95%;
  max-height: 95%;

  padding: 20px 30px;
  background: white;
  border: 2px solid black;
  border-radius: 0.8em;
  z-index: 5;

  margin: auto;
  position: relative;

  display: flex;
  flex-direction: column;
}

.closeButton {
  position: absolute;
  top: 6px;
  right: 6px;
}

.closeIcon {
  font-size: 20px;
  color: black;
}
.closeIcon:hover {
  color: gray;
}

.caption {
  margin: 0;
}

.children {
  overflow-y: auto;
}
