.fileType {
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-gap: 8px;
  margin-top: 2ex;

  & > .typeTitle {
    font-weight: bold;
    text-transform: uppercase;
    color: var(--lightText);
  }

  & > .typeContent {
    padding-bottom: 1ex;
    border-bottom: 1px solid #ddd;

    & > .typeContentEmpty {
      color: var(--lightText);
    }

    & > .fileItem {
      display: flex;
      flex-direction: row;
      margin-bottom: 0.5ex;
      align-items: center;

      & > span {
        flex: 1;
      }
    }

    & > .fileItem:last-child {
      margin-bottom: 0
    }
  }
}

.addFilesButton {
  font-size: inherit;
}

.groupButton {
  font-size: inherit;
  font-weight: bold;
  padding: 0;
  margin: 0 4px;
}

.confirmButton {
  margin-left: auto;
}

.flex {
  display: flex;
}

.lightText {
  color: var(--lightText);
}

.typeMenu {
  left: -180px;
}
