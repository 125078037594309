@value clrProgressSuccess: #9dc66c;
@value clrProgressError: coral;

.container {
    background-color: white;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 400px;
    height: fit-content;
    border-bottom: solid lightgrey 1px;
}

.item {
    padding: 8px 16px;
    position: relative;
    border: lightgrey solid 1px;
    border-bottom: none;
    display: flex;
}

.itemProgress {
    width: 32px;
    align-self: center;
    position: relative;
}

.itemContent {
    margin-left: 10px;
    flex: 1;
}

.itemHeader {
    font-size: 12px;
}

.action {
    position: relative;
    font-size: 11px;
    display: flex;
}

.actionHeader{
    flex: 2;
    /*white-space: nowrap;*/
    /*text-overflow: ellipsis;*/
    /*overflow: hidden;*/
}

.actionProgress {
    flex: 1;
    margin-left: 8px;
    align-self: center;
}

.bottomBar {
    display: flex;
    flex-direction: row;
    background-color: #eee;
}

.bottomBar .bottomButton {
    background-color: #eee;
    border: solid lightgrey 1px;
    border-radius: 0;
    text-decoration: none;
    width: 24px;
    height: 100%;
    border-bottom: none;
}

.totals {
    position: relative;
    flex: 1;
    font-size: 13px;
    display: flex;
    justify-content: center;
    background-color: #eee;
}

.totalsHeader {
    z-index: 2;
    text-align: center;
}

.progressContainer {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
}

.progressSuccess {
    background-color: clrProgressSuccess;
}

.progressError {
    background-color: clrProgressError;
}
