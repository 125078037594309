.container {
    width: 100%;
    justify-content: center;
}

.text {
    text-align: center;
}

.example {
    width: fit-content;
    margin: 50px auto 0 auto;
}

.button {
    cursor: default;
}
