.editContainer {
  display: flex;
  flex-direction: row;
}

.editContainer > div:first-child {
  flex: 1;
}

.saveWrapper {
  text-align: right;
  padding-left: 30px;
}

form .toggleEdit {
  margin-top: 4px;
}

.horizontal {
    display: flex;
    flex-direction: row;
}

.horizontal > div {
    flex: 1;
}

.horizontal > div:first-child {
    flex: unset;
    width: 220px;
    margin-right: 20px;
}

.fields {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}
