.parent {
  color: var(--lightText);
}

.parentBtn {
  text-transform: none;
  text-decoration: none;
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-family: inherit;
}

.title {
  flex: 1;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  text-align: left;
  margin-top: 0;
}
