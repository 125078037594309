.copyButton {
    margin-right: 20px;
}

form .toggleEdit {
  margin-top: 4px;
}

.editContainer {
  display: flex;
  flex-direction: row;
}

.editContainer > div:last-child {
  flex: 1;
}

.saveWrapper {
  text-align: center;
  padding-left: 30px;
}

.shareLink {
  cursor: pointer;
    text-transform: none;
}

label.label {
  width: 124px;
}

.flexRow {
    display: flex;
    flex-direction: row;
}

.flex1 {
    flex: 1;
    align-self: center;
}

.header {
    margin-top: 0;
    margin-bottom: 6px;
    text-align: left;
}
