.container {
    width: 100%;
    justify-content: center;
}

.text {
    line-height: 1.3em;
    margin: 20px 30px;
    text-align: left;
}

.imageExampleSync {
    margin: -60px auto 0 auto;
    padding-left: 110px;
    display: block;
}
