.container {
    padding: 133px 0 0 20px;
    height: 561px;
    color: white;
    font-family: 'Henriette Black', Georgia, serif;
}

.textBlock {
    font-size: 34px;
    line-height: 1.3;
    width: 14em;
    margin-bottom: 40px;
}

.textBlock div:nth-child(n+2) {
    margin-top: 63px;
}

.button {
    margin-left: 50px;
    width: 325px;
    font-size: 18px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
}
