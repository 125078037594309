.container {
    display: flex;
    flex-direction: row;
}

.schema {
    transform: scale(0.51);
    margin: -58px -58px 0 -118px;
}

.text {
    font-size: 18px;
    margin-bottom: 20px;
}

.textItalic {
    margin-top: 40px;
    font-style: italic;
    font-size: 28px;
    margin-bottom: 20px;
}

.textItalic:last-child {
    margin-bottom: 0;
}
