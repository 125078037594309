.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 5px 0 30px 0;
    position: relative;
    margin-top: 20px;
    border-bottom: 1px solid #ddd;
}

.header {
    width: fit-content;
}

.headerFull {
    width: 100%;
}

.content {
    flex: 1;
    padding: 0 20px 0 15px;
}

.footer {
    width: fit-content;
}

.footerRight {
    position: absolute;
    top: 0;
    right: 0;
    line-height: 40px;
}

.mediaGroupIcon {
    width: 58px;
    text-align: center;
    display: inline-block;
}

.mediaGroupTitle {
    display: inline-block;
    padding-top: 5px;
    width: 90px;
    text-align: right;
    white-space: nowrap;
    vertical-align: bottom;
    margin-right: 5px;
    color: #999;
}

.file {
    display: flex;
    border-bottom: 0.5px solid #ddd;
}

.withHover:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.file:last-child {
    border-bottom: none;
}

.fileActions {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.checkInput {
    padding-bottom: 1ex;
}

.leftPad0 {
    padding-left: 0;
}

.inlineBlock {
    display: inline-block;
}
