//.container {
//  display: flex;
//
//  & > div:first-child {
//    flex: 1;
//  }
//}

.flexHeader {
  display: flex;
  & > h3:first-child {
    flex: 1;
  }
}

.subheader {
  margin-top: 0;
  text-align: left;
}

.fields {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.buttons {
  display: flex;
  flex-direction: column;
}
