.videoDetails {
  display: flex;
}

.videoDetails > form {
  flex: 1;
  min-width: max-content;
}

.mediaContainer {
  display: flex;
  flex: 2;
  max-width: max-content;
}

.videoPlayer {
  padding-top: 10px;
  width: auto;
  align-items: first;
}

form .toggleEdit {
  margin-top: 4px;
}

.image {
  display: inline-block;
  max-width: 150px;
  max-height: 150px;
  /*margin-top: 20px;*/
  vertical-align: top;
}

.videoDetail {
  width: 100%;
}

.videoDetailFields {
  margin-top: 0;
}

label.label {
  width: 90px;
}

.value {
  padding: 10px 0;
  line-height: 20px;
}

.editContainer {
  display: flex;
  flex-direction: row;
}

.editContainer > div:first-child {
  flex: 1;
}

.saveWrapper {
  text-align: right;
  padding-left: 30px;
}

