.container {
    width: 100%;
    display: flex;
}

.textLine {
    margin: 20px 0;
    display: inline-block;
    text-align: center;
    width: 100%;
}

.imgArrow {
    display: inline-block;
    height: fit-content;
    margin: -30px 50px 0 -25px;
}
