.container {
    display: flex;
    height: 100%;
    flex-direction: column;
}

.page {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*min-width: 600px;*/
    /*min-height: 480px;*/
    flex: 1;
}

.buttons {
    display: flex;
    margin-top: 20px;
    position: relative;
    width: 100%;
    height: 38px;
}

.arrowButtons {
    position: absolute;
    top: 0;
    right: calc(50% - 35px);
}

.endButtons {
    position: absolute;
    top: 0;
    left: calc(75% - 65px);
}


.flex {
    flex: 1;
    text-align: center;
}
