@value clrBorder from '../../constants/colors.css';

.root {
    padding: 1em 0 0 0;
    overflow: hidden;

    /* border-top: 1px solid clrBorder; */
    margin: 5px 0 0 0;
    text-align: center;
}

.topMargin {
    margin-top: 30px;
}

.root > * {
    margin-left: 12px;
}

.root > *:first-child {
    margin-left: 0;
}
