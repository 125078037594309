.container {
    display: flex;
    flex-direction: row;
}

.container h1 {
    text-align: left;
}

.hands {
    display: inline-block;
    height: 62px;
    margin: -20px 0 0 10px;
}

.left {
    flex: 1;
}

.right {
    width: 310px;
    /*flex: 1;*/
    margin-top: 30px;
}

.image {
    width: 310px;
}

.leftText {
    margin-bottom: 20px;
    max-width: 500px;
}

.leftText:last-child {
    margin-bottom: 0;
}

.rightText {
    font-style: italic;
    margin: 0 35px;
}
