@keyframes spin { 100% { transform:rotate(360deg); } }


.container {
    position: relative;
    margin-right: -20px;
    width: 100%;
}

.container .loading {
  position: absolute;
  top: 11px;
  right: 35px;
  animation: spin 1.5s linear infinite;
  width: 22px;
  height: 22px;
  z-index: 1;
}

.input {
    background: url('./resources/icon_search.svg') no-repeat right 15px center;
    background-size: 20px 20px;
    text-align: center;
    border-color: transparent;
    box-shadow: none;
    width: 150px;
    transition: all 0.5s ease-in-out;
    color: #aaa;
    cursor: pointer;
}

.input::placeholder {
    color: #aaa;
}

.input:focus {
    width: 100%;
    text-align: left;
    border: 1px solid #e0e0e0;
    box-shadow: inset 0 1px 1px #e9e7e7;
    color: #000;
    cursor: text;
}

.iconOff {
    background-image: none;
}
