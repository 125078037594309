.container {
    position: relative;
    display: inline-block;
    margin-right: 10px;
}

.spinner {
    opacity: 0.3;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12px;
    margin-top: -11px;
}

.spinnerNumber {
    height: 1em;
}

.count {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 6pt;
    background-color: black;
    color: white;
    border-radius: 3px;
    line-height: normal;
    padding: 0 2px;
}
