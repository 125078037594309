.button {
    margin: 3px 0 0 5px;
    padding: 0;
    align-self: center;
}

/*.button > btn-text {*/
/*    padding: 0;*/
/*}*/

.container {
    display: flex;
}

.container > div:first-child {
    flex: 1;
}

.valueLength {
    float: right;
}

.red {
    color: red;
    font-weight: bold;
}

.selectContainer > div > input {
  margin-top: 4px;
}

.selectContainer > div > ul {
  vertical-align: middle;
}
