.loading {
  animation: spin 1.5s linear infinite;
  width: 22px;
  height: 22px;

  &.big {
    width: 3rem;
    height: 3rem;
  }

  &.full {
    width: 100%;
  }
}

.darkBackground {
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
  background-color: rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes spin { 100% {transform: rotate(360deg);} }
