.containerWrapper {
    position: fixed;
    background-color: #00000000;
    top: 0;
    left: 158px; /* nav width */
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    z-index: 4;
}

.windowContainer {
    min-width: 400px;
    max-height: calc(100% - 40px);
    overflow-y: auto;

    padding: 20px 30px;
    background: white;
    border: 2px solid black;
    border-radius: 0.8em;
    z-index: 5;

    margin: auto;
    position: relative;
}

.windowNoContainer{
    padding: 0;
    min-width: unset;
    max-height: unset;
    border: none;
    border-radius:unset;
}

.closeButtonContainer {
    position: absolute;
    right: 8px;
    top: 8px;
}

.closeButtonContainer > button.closeButton {
    background-color: white;
    color: gray;
    padding: 0;
    border: none;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 50%;
    font-size: 20px;
}

.closeButton > svg {
  width: auto;
  height: 20px;
}

.background {
    display: none;
}

.backgroundModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.15);
    z-index: 3;
    display: block;
}

.noBackground {
    background-color: #00000000;
}
