.formContainer {
    display: flex;
    flex-direction: column;
}

.password {
    padding-bottom: 0;
}

.resetPasswordButton {
    width: fit-content;
    align-self: center;
}
