.background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-left: 158px; /* nav width */;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noBackground {
  background-color: #00000000;
}

.window {
  min-width: 300px;
  max-height: calc(100% - 40px);
  overflow-y: auto;

  padding: 20px 30px;
  background: white;
  border: 2px solid black;
  border-radius: 0.8em;
  z-index: 4;

  position: relative;
}

.closeButton {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 20px;
  color: gray;
}
