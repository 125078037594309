.button.check {
    padding-left: 0;
    text-transform: none;
    text-decoration: none;
}

.icon {
    width: 26px;
    display: inline-block;
    text-align: left;
}

.underlined {
    border-bottom: 0.5px solid #ddd;
}
