.container {
    height: fit-content;
    border: solid 1px #ddd;
    border-radius: 4px;
    padding: 5px;
    display: flex;
    flex-direction: row;
}

.container > .icon {
    width: 48px;
    height: 48px;
    margin-right: 10px;
}

.messageBlock {
    flex: 1;
    width: 100%;
}

.title {
    font-size: larger;
    font-weight: bold;
    height: 1.5em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.message {
    max-height: 100px;
    overflow: auto;
}
