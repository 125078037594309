.rowTotal {
  display: grid;
  grid-template-columns: 80px 1fr;
  grid-gap: 4px;
}

.spinner {
  position: relative;
  min-height: 80px;
}
