@value clrProgressSuccess: #9dc66c;
@value clrProgressError: coral;

.item {
    padding: 8px 16px;
    position: relative;
    border: lightgrey solid 1px;
    border-bottom: none;
    display: flex;
}

.itemProgress {
    width: 32px;
    align-self: center;
    position: relative;
}

.itemContent {
    margin-left: 10px;
    flex: 1;
}

.itemHeader {
    font-size: 12px;
}

.action {
    position: relative;
    font-size: 11px;
    display: flex;
}

.actionHeader{
    flex: 2;
    /*white-space: nowrap;*/
    /*text-overflow: ellipsis;*/
    /*overflow: hidden;*/
}

.actionProgress {
    flex: 1;
    margin-left: 8px;
    align-self: center;
}
