.container {
    position: relative;
    height: 84px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.wrapper {
    height: fit-content;
    padding: 0 10px;
}

.menu {
    display: inline-flex;
    height: 100%;
    padding-bottom: 20px;
    align-items: flex-end;
    margin-left: 40px;
    flex: 1;
}

.menuItem {
    font-family: 'Henriette Black', Georgia, serif;
    font-size: 25px;
    text-transform: none;
    text-decoration: none;
    border-right: solid 1px #b3b3b3;
    border-radius: unset;
    margin: 0;
}

.menuItem:last-child {
    border-right: none;
}


.logoWrapper {
    margin: 5px 0 0 5px;
    display: inline-block;
}

.right {
    height: min-content;
    margin-right: 25px;
}

.hr {
    margin: 0 80px 10px 80px;
}
