.container {
    width: 100%;
    justify-content: center;
}

.text {
    line-height: 1.3em;
    margin: 20px 30px;
    text-align: left;
}

.imageExampleActions {
    margin: -20px auto -30px auto;
    display: block;
}
