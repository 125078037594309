.videoDetailFields {
  margin-top: 0;
}

label.label {
  width: 140px;
}

.value {
  padding: 10px 0;
  line-height: 20px;
}

.editContainer {
  display: flex;
  flex-direction: row;
}

.editContainer > div:first-child {
  flex: 1;
}

.saveWrapper {
  text-align: right;
  padding-left: 30px;
}

form .toggleEdit {
  margin-top: 4px;
}
