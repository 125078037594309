.schema {
    position: relative;
    width: fit-content;
    margin: 30px auto 40px auto;
}

.title {
    top: 125px;
    width: fit-content;
}

.text {
    top: 165px;
    width: 210px;
    font-size: 15px;
}

.leftTitle {
    left: -20px;
}

.rightTitle {
    right: -10px;
}

.rightText {
    right: -80px;
}

.leftText {
    left: -60px;
}
