@font-face {
  font-family: 'Henriette Black';
  src: url('./fonts/Henriette-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+000D-FB04;
}

:root {
  --bgColor: white;
  --btnPrimaryBg: black;
  --btnPrimaryText: white;
  --btnSecondaryBg: #d1d3d4;
  --btnSecondaryText: white;
  --btnWarningBg: #bf1e2d;
  --btnWarningText: white;
  --btnTransparentBg: #00000000;
  --btnTransparentText: black;
  --btnNoneText: black;
  --btnNoneHover: #eee;
  --textColor: #000;
  --lightText: #9e9e9e;
  --bgHover: #00000019;
  --bg2ndRow: #00000008;
  --disabledOpacity: 0.5;
  --inputValidateFlagWidth: 20px
}

html, #root {
    font-size: 100%;
    height: 100%;
    position: relative;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Lato", -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: var(--textColor);
    /*color: #333;*/
    line-height: 1.5;
    height: 100%;
  min-width: 980px;
}

button {
    font-family: "Lato", -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif;
}

/*body * {*/
/*    font-family: "Lato", -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif;*/
/*}*/

.appContainer {
    display: grid;
    grid-template-rows: 100px 1fr fit-content();
    grid-template-columns: min-content 1fr;
    height: 100%;
}

header {
    grid-column: 2;
    text-align: center;
}

nav {
    grid-column: 1;
    grid-row: 1 / 4;
    white-space: nowrap;
    margin: 0 23px 0 0;
    width: 158px;
    background-color: black;
    display: flex;
    flex-direction: column;
}

main {
    margin-right: 3px;
    padding: 0 3px 0 3px;
    height: calc(100vh - 95px - 2px);
    overflow: auto;
    position: relative;
}

footer {
    /*height: fit-content;*/
    text-align: center;
    position: fixed;
    bottom: 0;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
}
::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 5px;
    background: rgba(0,0,0,.25);
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}
::-webkit-scrollbar-track {
    background: rgba(0,0,0,.1);
    border-radius: 0;
}
::selection {
    background-color: #cce2ff;
    color: rgba(0,0,0,.87);
}

body.desktop {
    border: solid 1px #d5d5d5;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

button:disabled {
    opacity: 0.5;
}

main .input-row {
    padding: 10px 0;
}

main .input-row label {
    color: #aaa;
    font-size: 14px;
    padding-bottom: 5px;
}

main .text-input {
    padding: 10px 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    color: #000;
    box-sizing: border-box;
    width: 100%;
}

main .text-input.read-only {
    border: none;
    padding-left: 0;
}

main .text-input:focus {
    outline: none;
}

main .form-buttons {
    padding-top: 30px;
}

main .react-tabs__tab-list {
    border-bottom: none;
    display: flex;
    flex-direction: row;
}

h1,
h2,
h3 {
    font-family: 'Henriette Black', Georgia, serif;
    text-align: center;
    margin: 20px 0 30px;
    position: relative;
}

h1 {
    font-size: 40px;
}

h2 {
    font-size: 31px;
}

h3 {
    font-size: 22px;
}

h1 .right-container,
h2 .right-container,
h3 .right-container {
    position: absolute;
    right: 0;
    margin: 3px 0 3px 15px;
    top: 0;
    line-height: 1;
}

h2 .right-container {
    margin-top: 0;
    margin-bottom: 0;
}

h3 .right-container {
    margin-top: -5px;
    margin-bottom: -5px;
}

h1 .right-button,
h2 .right-button,
h3 .right-button {
    float: right;
    margin: 3px 0 3px 15px;
}

h2 .right-button {
    margin-top: 0;
    margin-bottom: 0;
}

h3 .right-button {
    margin-top: -5px;
    margin-bottom: -5px;
}

.margin-top-none {
    margin-top: 0;
}

h1.left,
h2.left,
h3.left {
    text-align: left;
}

main h1 .light {
    color: #9e9e9e;
}

.uppy-StatusBar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100% - 400px);
}

a {
    color: black;
    text-decoration: none;
}

a:hover {
    color: #333;
}
.react-router-modal__modal {
    border-radius: 0.8em;
    border: 2px solid black;
    left: calc(50% + 158px/2);
}

.react-router-modal__backdrop {
    background-color: rgba(0, 0, 0, 0.15)
}

.rw-widget-picker {
    min-height: 44px;
}

.rw-widget-container {
    border-radius: 10px;
    border: 1px solid #e0e0e0;
}

.rw-multiselect-taglist {
    margin-left: 0.25rem;
}

.rw-multiselect-tag {
  border-radius: 0.7em;
  font-size: 14px;
  padding: 2px 8px;
}

.rw-multiselect > .rw-widget-picker {
    min-height: 44px;
}

.rw-multiselect .rw-input-reset[readonly] {
    display: none;
}

.ui.segment.active.tab {
    border: none;
    box-shadow: none;
}

.ui.secondary.menu {
    margin-right: 0;
    margin-left: 0;
}
