.container.left0 {
  margin: 0;
  padding-left: 0;
  text-transform: none;
  text-decoration: none;
}

.icon {
  width: 26px;
  display: inline-block;
  text-align: left;
}

