.container {
    justify-content: center;
    width: 100%;
}

.text {
    text-align: center;
    margin-bottom: 0;
}

.image {
    display: block;
    margin: 0 auto;
    height: 170px;
}

