.button.left0 {
    padding-left: 0;
    text-transform: none;
    text-decoration: none;
    margin-left: 0;
}

.icon {
    width: 26px;
    display: inline-block;
    text-align: left;
}
