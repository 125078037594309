.shortView {
  display: flex;
  height: 44px;
}

.quickSearch {
}
.quickSearchRoot {
  flex: 1;
}

.appliedFilters {
  align-self: center;
}

.buttonCross {
  margin-left: 4px;
}

.filterButton {
  margin-right: 1rem;
}

.filterValueButton {
  font-size: 14px;
  position: relative;
}

.label {
  color: var(--lightText);
}
